import React, { ForwardRefRenderFunction, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { CategoryForNavMenuFooterFragment } from '../NavMenuFooter/NavMenuFooter.generated'
import NavMenuFooter from '../NavMenuFooter'
import NavMenuAccountHeader from '../NavMenuAccountHeader'
import ChevronLeft from '../../icons/chevron-left.svg'
import styles from './styles.css'

export interface SharedNavMenuWrapperProps {
  showPathCta: boolean
}

interface NavMenuWrapperBaseProps extends SharedNavMenuWrapperProps {
  containerId: string
  navigationCategory?: CategoryForNavMenuFooterFragment
  containerClassName?: string
  isActive: boolean
  isChildActive: boolean
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  showAccountHeader?: boolean
}

interface NavMenuWrapperWithHeaderProps extends NavMenuWrapperBaseProps {
  label: string
  onBack: () => void
}

interface NavMenuWrapperWithoutHeaderProps extends NavMenuWrapperBaseProps {
  label?: never
  onBack?: never
}

type NavMenuWrapperProps = NavMenuWrapperWithHeaderProps | NavMenuWrapperWithoutHeaderProps

/**
 * Handles header, footer, and resetting scroll when closed or child is opened
 */
const NavMenuWrapperRenderFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  React.PropsWithChildren<NavMenuWrapperProps>
> = (props, ref) => {
  const {
    containerId,
    containerClassName,
    isActive,
    isChildActive,
    label,
    onBack,
    children,
    navigationCategory,
    showPathCta,
    onNavigate,
    showAccountHeader = true
  } = props

  useEffect(() => {
    // Reset the scroll position when closed or when a child nav is opened.
    //  The child nav is fixed relative to this nav. Resetting the scroll position
    //  ensures that the child is positioned at the top correctly. This causes the
    //  ui to shift before the child completes its opening transition.
    if (!isActive || isChildActive) {
      document.getElementById(containerId)?.scrollTo({ top: 0 })
    }
  }, [isActive, isChildActive])

  return useMemo(
    () => (
      <div
        ref={ref}
        id={containerId}
        className={classnames(styles.navigationContainer, containerClassName, {
          // slides this nav from the right and makes it visible
          [styles.activeNavigationContainer]: isActive,
          // hide overflow to remove scroll bar. otherwise, the scroll bar is visible under the child nav
          [styles.overflowHidden]: isChildActive
        })}
      >
        <div>
          {showAccountHeader && <NavMenuAccountHeader onNavigate={onNavigate} />}
          {label && (
            <button
              data-testid={`mobile-nav-header-button-${containerId.toLowerCase()}`}
              className={styles.navigationHeader}
              onClick={e => {
                e.stopPropagation()
                onBack()
              }}
            >
              <ChevronLeft />
              {label}
            </button>
          )}
          {children}
        </div>
        <NavMenuFooter
          navigationCategory={navigationCategory}
          onNavigate={onNavigate}
          showPathCta={showPathCta}
        />
      </div>
    ),
    [isActive, isChildActive, showPathCta]
  )
}

const NavMenuWrapper = React.forwardRef(NavMenuWrapperRenderFunction)

export default NavMenuWrapper
