import React from 'react'
import loggerClient from '#src/app/services/loggerClient'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import { CategoryNavigationDecoratedGroupForNavMenuL2Fragment } from './NavMenuL2.generated'
import SubSectionSeparator from '../SubSectionSeparator'
import SectionSeparator from '../SectionSeparator'
import ReferralUpsell from '../ReferralUpsell'
import NavMenuWrapper, { SharedNavMenuWrapperProps } from '../NavMenuWrapper'
import NavMenuL3 from '../NavMenuL3'
import NavMenuItemSaleLink from '../NavMenuItemSaleLink'
import NavMenuItemLink from '../NavMenuItemLink'
import NavMenuItemButton from '../NavMenuItemButton'
import NavListHeader from '../NavListHeader'
import NavListContent from '../NavListContent'
import NavListContainer from '../NavListContainer'
import { useActiveNavMenuL2Atom, useActiveNavMenuL3Atom } from '../../atoms'

interface NavMenuL2Props extends SharedNavMenuWrapperProps {
  navigationGroup: CategoryNavigationDecoratedGroupForNavMenuL2Fragment
  otherNavigationGroups: CategoryNavigationDecoratedGroupForNavMenuL2Fragment[]
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

/**
 * L2 - Navigation revisions - Top-level Categories
 */
const NavMenuL2: React.FC<NavMenuL2Props> = ({
  navigationGroup,
  otherNavigationGroups,
  onNavigate,
  showPathCta
}) => {
  const referralUrl = useReferralUrl({ source: 'nav-bar' })
  const [activeNavMenuL2, setActiveNavMenuL2] = useActiveNavMenuL2Atom()
  const [activeNavMenuL3, setActiveNavMenuL3] = useActiveNavMenuL3Atom()

  return (
    <NavMenuWrapper
      containerId={navigationGroup.slug}
      isActive={activeNavMenuL2 === navigationGroup.slug}
      isChildActive={
        !!activeNavMenuL3 &&
        navigationGroup.navigationRevisions.some(navigation => navigation.id === activeNavMenuL3)
      }
      label={navigationGroup.label}
      onBack={() => setActiveNavMenuL2(undefined)}
      onNavigate={onNavigate}
      showPathCta={showPathCta}
    >
      <NavListContainer>
        {navigationGroup.navigationRevisions.map(currentNavigation => {
          if (currentNavigation.sections.length > 0) {
            return (
              <NavMenuItemButton
                key={currentNavigation.id}
                label={currentNavigation.displayLabel}
                onClick={() => setActiveNavMenuL3(currentNavigation.id)}
              >
                <NavMenuL3
                  navigation={currentNavigation}
                  otherNavigations={navigationGroup.navigationRevisions.filter(
                    navigation => navigation.id !== currentNavigation.id
                  )}
                  onNavigate={onNavigate}
                  showPathCta={showPathCta}
                />
              </NavMenuItemButton>
            )
          }

          if (!currentNavigation.displayLabelLink?.relativeConsumerWebPath) {
            loggerClient.captureError(
              new Error(
                `Attempted to render navigation link without link: ${currentNavigation.displayLabel} ${currentNavigation.category?.slug}`
              )
            )
            return null
          }

          return (
            <NavMenuItemLink
              key={currentNavigation.id}
              label={currentNavigation.displayLabel}
              to={currentNavigation.displayLabelLink.relativeConsumerWebPath}
              noFollow={!currentNavigation.displayLabelLink.seoShouldFollow}
              onNavigate={onNavigate}
            />
          )
        })}
      </NavListContainer>
      <SubSectionSeparator />
      <NavListContainer>
        <NavMenuItemSaleLink onNavigate={onNavigate} />
      </NavListContainer>
      <NavListContent onNavigate={onNavigate} />
      {referralUrl ? (
        <ReferralUpsell referralUrl={referralUrl} onNavigate={onNavigate} />
      ) : (
        <SectionSeparator />
      )}
      <NavListHeader>Other departments</NavListHeader>
      <NavListContainer>
        {otherNavigationGroups.map(otherNavigationGroup => (
          <NavMenuItemButton
            key={otherNavigationGroup.slug}
            label={otherNavigationGroup.label}
            onClick={() => setActiveNavMenuL2(otherNavigationGroup.slug)}
          />
        ))}
      </NavListContainer>
    </NavMenuWrapper>
  )
}

export default NavMenuL2
