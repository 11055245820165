import React from 'react'
import NavMenuItem, { NavMenuItemProps } from '../NavMenuItem'
import NavLink, { NavLinkProps } from '../NavLink'

const NavMenuLink: React.FC<
  NavLinkProps & Omit<NavMenuItemProps, 'hideChevron'> & { testId?: string }
> = ({ to, noFollow, onClick, onNavigate, label, copy, icon, className, testId }) => (
  <li data-testid={testId}>
    <NavLink to={to} noFollow={noFollow} onClick={onClick} onNavigate={onNavigate}>
      <NavMenuItem label={label} copy={copy} icon={icon} className={className} hideChevron />
    </NavLink>
  </li>
)

export default NavMenuLink
