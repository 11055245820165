import React from 'react'
import styles from './styles.css'

interface NavListContainerProps {
  testId?: string
}

const NavListContainer: React.FC<React.PropsWithChildren<NavListContainerProps>> = ({
  children,
  testId
}) => (
  <ul className={styles.navigationList} data-testid={testId}>
    {children}
  </ul>
)

export default NavListContainer
