import React from 'react'
import { LocationDescriptor } from 'history'
import { Link } from '@deal/router'
import styles from './styles.css'

interface ReferralUpsellProps {
  referralUrl: LocationDescriptor
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const ReferralUpsell: React.FC<ReferralUpsellProps> = ({ referralUrl, onNavigate }) => (
  <div className={styles.container}>
    <Link to={referralUrl} onClick={onNavigate}>
      <div>
        <div className={styles.header}>Refer a friend</div>
        <div className={styles.subheader}>Give 10% off, get 10% off</div>
      </div>
    </Link>
  </div>
)

export default ReferralUpsell
