import React from 'react'
import styles from './styles.css'

export interface NavButtonProps {
  onClick: () => void
  disabled?: boolean
}

const NavButton: React.FC<React.PropsWithChildren<NavButtonProps>> = ({
  onClick,
  disabled,
  children
}) => (
  <button
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    disabled={disabled}
    className={styles.button}
  >
    {children}
  </button>
)

export default NavButton
