import { useActiveNavMenuL2Atom, useActiveNavMenuL3Atom, useActiveNavMenuL4Atom } from '../atoms'

export const useResetMobileNavMenuScrollPositions = () => {
  const [activeNavMenuL2] = useActiveNavMenuL2Atom()
  const [activeNavMenuL3] = useActiveNavMenuL3Atom()
  const [activeNavMenuL4] = useActiveNavMenuL4Atom()

  return () => {
    document.getElementById('site-navigation')?.scrollTo({ top: 0 })
    activeNavMenuL2 && document.getElementById(activeNavMenuL2)?.scrollTo({ top: 0 })
    activeNavMenuL3 && document.getElementById(activeNavMenuL3)?.scrollTo({ top: 0 })
    activeNavMenuL4 && document.getElementById(activeNavMenuL4)?.scrollTo({ top: 0 })
  }
}
