import React from 'react'
import { Link } from '@deal/router'
import { Image } from '@deal/components'
import { TopListEntryPointClickedEvent } from '#src/app/events/TopListEntryPointClickedEvent'
import { SellableEditorialTopListRevisionForMobileNavTopListFragment } from './MobileNavTopLists.generated'
import SubSectionSeparator from '../SubSectionSeparator'
import NavListHeader from '../NavListHeader'
import styles from './styles.css'

export interface MobileNavTopListsProps {
  topLists: SellableEditorialTopListRevisionForMobileNavTopListFragment[]
}

const MobileNavTopLists: React.FC<MobileNavTopListsProps> = ({ topLists }) => {
  if (!topLists?.length) {
    return null
  }

  return (
    <>
      <SubSectionSeparator />
      <ul className={styles.topLists}>
        <NavListHeader>Top Lists</NavListHeader>
        {topLists.slice(0, 5).map(topList => {
          const image = topList.itemGroups.at(0)?.items.at(0)?.sellable.primaryImage
          return (
            <Link
              to={`/top-lists/${topList.friendlyId}/${topList.slug}`}
              key={topList.id}
              onClick={() =>
                window.tracking?.track(
                  new TopListEntryPointClickedEvent({
                    entry_point: 'header-nav',
                    top_list_id: topList.id
                  })
                )
              }
            >
              <li className={styles.topList}>
                {image && (
                  <div className={styles.topListImageContainer}>
                    <Image
                      id={image.id}
                      src={image.url}
                      className={styles.topListImage}
                      sizes="20px"
                    />
                  </div>
                )}
                <div className={styles.topListTitle}>{topList.title}</div>
              </li>
            </Link>
          )
        })}
      </ul>
    </>
  )
}
export default MobileNavTopLists
