import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ArticleHubEntryPointClickedEventProperties {
  entry_point: ConsumerTracking.ArticleEntryPoint
}

export class ArticleHubEntryPointClickedEvent extends Event<
  ArticleHubEntryPointClickedEventProperties
> {
  static displayName = 'Article Hub Entry Point Clicked'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
