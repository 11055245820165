import React from 'react'
import { Image } from '@deal/components'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import { CategoryNavigationDecoratedGroupForNavMenuL1Fragment } from './NavMenuL1.generated'
import SubSectionSeparator from '../SubSectionSeparator'
import ReferralUpsell from '../ReferralUpsell'
import NavMenuWrapper, { SharedNavMenuWrapperProps } from '../NavMenuWrapper'
import NavMenuL2 from '../NavMenuL2'
import NavMenuItemSaleLink from '../NavMenuItemSaleLink'
import NavListContent from '../NavListContent'
import NavListContainer from '../NavListContainer'
import { useActiveNavMenuL2Atom } from '../../atoms'
import styles from './styles.css'

interface NavMenuL1Props extends SharedNavMenuWrapperProps {
  navigationGroups: CategoryNavigationDecoratedGroupForNavMenuL1Fragment[]
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

/**
 * L1 - Navigation groups - Verticals
 */
const NavMenuL1: React.FC<NavMenuL1Props> = ({ navigationGroups, onNavigate, showPathCta }) => {
  const [activeNavMenuL2, setActiveNavMenuL2] = useActiveNavMenuL2Atom()
  const referralUrl = useReferralUrl({ source: 'nav-bar' })

  return (
    <NavMenuWrapper
      containerId="site-navigation"
      isActive
      isChildActive={!!activeNavMenuL2}
      onNavigate={onNavigate}
      showPathCta={showPathCta}
    >
      <div className={styles.verticalsListContainer}>
        <div className={styles.verticalsListHeader}>Choose a department</div>
        <ul className={styles.verticalsList}>
          {navigationGroups.map(currentNavigationGroup => {
            const imgUrl = currentNavigationGroup.assets?.mobileNativeBanner?.url
            const otherNavigationGroups = navigationGroups.filter(
              otherNavigationGroup => otherNavigationGroup.slug !== currentNavigationGroup.slug
            )
            return (
              <li
                key={currentNavigationGroup.slug}
                onClick={e => {
                  e.stopPropagation()
                  setActiveNavMenuL2(currentNavigationGroup.slug)
                }}
                className={styles.button}
              >
                <div
                  className={styles.verticalCard}
                  data-testid={`mobile-nav-vertical-card-${currentNavigationGroup.slug.toLowerCase()}`}
                >
                  {imgUrl && <Image src={imgUrl} size="360px" className={styles.verticalCardImg} />}
                  <span className={styles.verticalLabel}>{currentNavigationGroup.label}</span>
                </div>
                <NavMenuL2
                  navigationGroup={currentNavigationGroup}
                  otherNavigationGroups={otherNavigationGroups}
                  onNavigate={onNavigate}
                  showPathCta={showPathCta}
                />
              </li>
            )
          })}
        </ul>
      </div>
      <SubSectionSeparator />
      <NavListContainer>
        <NavMenuItemSaleLink onNavigate={onNavigate} />
      </NavListContainer>
      <NavListContent testId="site-navigation-content" onNavigate={onNavigate} />
      {referralUrl && <ReferralUpsell referralUrl={referralUrl} onNavigate={onNavigate} />}
    </NavMenuWrapper>
  )
}

export default NavMenuL1
