// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForNavMenuFooterFragmentDoc } from '../NavMenuFooter/NavMenuFooter.generated';
import { CategoryForFooterNavItemPathButtonFragmentDoc } from '../NavMenuFooter/FooterNavItemPathButton/FooterNavItemPathButton.generated';
import { ArticleRevisionForMobileNavArticleCarouselFragmentDoc } from '../MobileNavArticleCarousel/MobileNavArticleCarousel.generated';
export type FormattedCategoryNavigationSectionForNavMenuL4Fragment = { __typename: 'FormattedCategoryNavigationSection', id: string, displayLabel: string, displayLabelLink?: { __typename: 'CategoryNavigationArticleLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationCustomWebLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationDepartmentLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedCategoryLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedDealsLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | null, items: Array<{ __typename: 'CategoryNavigationSectionItem', id: any, displayLabel: string, displayLabelLink?: { __typename: 'CategoryNavigationArticleLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationCustomWebLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationDepartmentLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedCategoryLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedDealsLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | null }> };

export type FormattedCategoryNavigationRevisionForNavMenuL4Fragment = { __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, sections: Array<{ __typename: 'FormattedCategoryNavigationSection', id: string, displayLabel: string }>, category?: { __typename: 'Category', id: any, slug: string, department?: { __typename: 'Department', id: any, slug: string } | null } | null, associatedArticles: Array<{ __typename: 'ArticleRevision', id: any, articleId: any, friendlyId: string, slug: string, title: string, featuredImage?: { __typename: 'ArticleMedia', title?: string | null, alternativeText?: string | null, media: { __typename: 'MediaFile', id: any, url: string } } | null }> };

export const FormattedCategoryNavigationSectionForNavMenuL4FragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormattedCategoryNavigationSectionForNavMenuL4"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormattedCategoryNavigationSection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabelLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"relativeConsumerWebPath"}},{"kind":"Field","name":{"kind":"Name","value":"seoShouldFollow"}}]}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabelLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"relativeConsumerWebPath"}},{"kind":"Field","name":{"kind":"Name","value":"seoShouldFollow"}}]}}]}}]}}]} as unknown as DocumentNode;
export const FormattedCategoryNavigationRevisionForNavMenuL4FragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormattedCategoryNavigationRevisionForNavMenuL4"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormattedCategoryNavigationRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}},{"kind":"Field","name":{"kind":"Name","value":"sections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}}]}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForNavMenuFooter"}}]}},{"kind":"Field","name":{"kind":"Name","value":"associatedArticles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ArticleRevisionForMobileNavArticleCarousel"}}]}}]}}]} as unknown as DocumentNode;