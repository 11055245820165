import React from 'react'
import useFeedHomePageEligibilityCheck from '#src/app/services/for-you/hooks/useFeedHomePageEligibilityCheck'
import { CategoryForNavMenuFooterFragment } from './NavMenuFooter.generated'
import HelpIcon from './help-center.svg'
import FooterNavItemPathButton from './FooterNavItemPathButton'
import FooterNavItemLink from './FooterNavItemLink'
import styles from './styles.css'

interface Props {
  navigationCategory?: CategoryForNavMenuFooterFragment | null
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  showPathCta: boolean
}

const NavMenuFooter: React.FC<Props> = ({ navigationCategory, onNavigate, showPathCta }) => {
  const department = navigationCategory?.department
  const reviewsPageUrl = department?.slug ? `/reviews/${department.slug}` : '/reviews'
  const eligibleForFeedHomePage = useFeedHomePageEligibilityCheck()
  return (
    <div className={styles.container}>
      <div className={styles.header}>Need some help?</div>
      <ul className={styles.list}>
        <FooterNavItemLink
          to="/support"
          label="Help Center"
          copy="Find answers online at any time"
          icon={HelpIcon}
          onNavigate={onNavigate}
        />
        {showPathCta && <FooterNavItemPathButton navigationCategory={navigationCategory} />}
      </ul>
      <div className={styles.header}>Curated</div>
      <ul className={styles.list}>
        <FooterNavItemLink to="/company/about" label="About us" onNavigate={onNavigate} />
        <FooterNavItemLink to="/company/experts" label="Meet The Experts" onNavigate={onNavigate} />
        {eligibleForFeedHomePage ? (
          <FooterNavItemLink to="/for-you" label="Community" onNavigate={onNavigate} />
        ) : (
          <FooterNavItemLink to={reviewsPageUrl} label="Reviews" onNavigate={onNavigate} />
        )}
      </ul>
    </div>
  )
}

export default NavMenuFooter
