import React, { useCallback, useContext, useState } from 'react'
import classnames from 'classnames'
import { Link, useHistory } from '@deal/router'
import { ChatContext } from '@deal/chat-firebase'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import useShowSearchBar from '#src/app/hooks/useShowSearchBar'
import { useDepartment } from '#src/app/context/department'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { useCart } from '#src/app/context/cart'
import { useIdentityContext } from '#src/app/containers/Identity'
import PageContent from '#src/app/components/PageContent'
import { useLeadsForMobileHeaderLazyQuery, useMobileHeaderQuery } from './MobileHeader.generated'
import SearchIcon from '../icons/search.svg'
import ChatIcon from '../icons/chat.svg'
import Search from '../common/Search'
import GlobalBanner from '../../GlobalBanner'
import { HeaderProps } from '../'
import WordmarkIcon from './icons/wordmark.svg'
import HamburgerIcon from './icons/hamburger.svg'
import CartIcon from './icons/cart.svg'
import AccountIcon from './icons/account.svg'
import MobileNavFlyout from './components/MobileNavFlyout'
import styles from './styles.css'

const ChatIconWithBadge: React.FC = () => {
  // ChatContext is asynchronously set, so isolating components that use this state
  // to minimize the amount of components that get re-rendered on page load.
  const { unreadMessageCount } = useContext(ChatContext)

  return (
    <Link to="/inbox">
      <ChatIcon />
      {unreadMessageCount ? (
        <span className={styles.navBarListItemBadge}>{unreadMessageCount}</span>
      ) : null}
    </Link>
  )
}

const CartIconWithBadge: React.FC = () => {
  const { cart } = useCart('CART_VIEW')

  const cartLineItemCount = cart?.purchasableItemsCount

  return (
    <>
      {!!cartLineItemCount && (
        <span className={styles.navBarListItemBadge}>{cartLineItemCount}</span>
      )}
      <CartIcon />
    </>
  )
}

export const HeaderMobile: React.FC<HeaderProps> = ({ category }) => {
  const history = useHistory()
  const { myself, incompleteUser } = useIdentityContext()
  const department = useDepartment()
  const [isSearchVisible, updateSearchVisibility] = useShowSearchBar()
  const [isNavFlyoutVisible, setIsNavFlyoutVisible] = useState(false)
  const { setCartVisibility } = useCartVisibilityContext()

  const isImpersonating = myself && myself.user.id !== myself.realUser.id

  const { data } = useMobileHeaderQuery({
    variables: {
      userId: myself?.user.id,
      isLoggedIn: !!myself && !incompleteUser
    }
  })

  const [fetchLeads] = useLeadsForMobileHeaderLazyQuery({ fetchPolicy: 'cache-and-network' })

  // show path cta if user is logged out or has no leads
  const showPathCta = !data?.leads?.count

  const navFlyoutOnClose = useCallback(() => {
    setIsNavFlyoutVisible(false)
    // update location state to interact with chat widget, this prevents scroll lock conflicts
    history.replace({
      ...history.location,
      state: {
        ...(history.location.state as any),
        isBrowseExpanded: false
      }
    })
  }, [history])

  return (
    <header className={styles.wrapper}>
      <GlobalBanner />
      <div
        className={classnames(styles.header, {
          [styles.isImpersonating]: isImpersonating
        })}
      >
        <PageContent className={styles.topBar}>
          <div className={styles.primaryNav}>
            <ul className={styles.navBarList}>
              <li className={styles.navBarListItem}>
                <button
                  onClick={() => {
                    // HeaderV2MobileQuery is an expensive query, when logged in and opening the nav,
                    //   lazy query the latest lead count to keep the path cta up-to-date.
                    if (myself && !incompleteUser) {
                      fetchLeads({
                        variables: {
                          userId: myself.user.id
                        }
                      })
                    }

                    setIsNavFlyoutVisible(true)
                    // update location state to interact with chat widget, this prevents scroll lock conflicts
                    history.replace({
                      ...history.location,
                      state: {
                        ...(history.location.state as any),
                        isBrowseExpanded: true
                      }
                    })
                  }}
                  data-testid="mobile-browse-menu-trigger"
                  aria-label="Navigation menu"
                >
                  <HamburgerIcon />
                </button>
              </li>
              <li className={styles.navBarListItem}>
                <button
                  onClick={() => updateSearchVisibility(true)}
                  data-testid="mobile-nav-search-menu-button"
                  aria-label="Search"
                >
                  <SearchIcon />
                </button>
              </li>
            </ul>
          </div>
          <Link
            to={
              department ? buildCategoryOrProductListingPageUrl(department.topLevelCategory) : '/'
            }
            className={styles.logo}
            aria-label="Home"
          >
            <WordmarkIcon />
          </Link>
          <nav aria-label="tertiary" className={styles.tertiaryNav}>
            <ul className={styles.navBarList}>
              {myself && !incompleteUser ? (
                <li className={styles.navBarListItem}>
                  <ChatIconWithBadge />
                </li>
              ) : (
                <li className={styles.navBarListItem}>
                  <Link to="/auth/login" aria-label="Account">
                    <AccountIcon />
                  </Link>
                </li>
              )}
              <li className={styles.navBarListItem}>
                <button
                  onClick={() => setCartVisibility(true)}
                  data-testid="mobile-nav-cart-button"
                  aria-label="Cart"
                >
                  <CartIconWithBadge />
                </button>
              </li>
            </ul>
          </nav>
        </PageContent>
      </div>

      <Search isOpen={isSearchVisible} onRequestClose={() => updateSearchVisibility(false)} />

      <MobileNavFlyout
        isOpen={isNavFlyoutVisible}
        onClose={navFlyoutOnClose}
        navigationGroups={data?.formattedCategoryNavigationsByGroup || []}
        category={category}
        showPathCta={showPathCta}
      />
    </header>
  )
}
