import React from 'react'
import classnames from 'classnames'
import ChevronRight from '../../icons/chevron-right.svg'
import styles from './styles.css'

export interface NavMenuItemProps {
  label: string
  copy?: string
  icon?: React.FC<React.PropsWithChildren<React.SVGAttributes<SVGElement>>>
  hideChevron?: boolean
  className?: string
}

const NavMenuItem: React.FC<NavMenuItemProps> = ({
  label,
  copy,
  icon: Icon,
  hideChevron,
  className
}) => (
  <div className={classnames(styles.navMenuItemOuterContainer, className)}>
    {Icon && <Icon className={styles.icon} />}
    <div className={styles.navMenuItemInnerContainer}>
      <div>
        <div>{label}</div>
        <div className={styles.navMenuItemCopy}>{copy}</div>
      </div>
      {!hideChevron && <ChevronRight />}
    </div>
  </div>
)

export default NavMenuItem
