import React from 'react'
import { ArticleHubEntryPointClickedEvent } from '#src/app/events/ArticleHubEntryPointClickedEvent'
import { SellableEditorialTopListRevisionForMobileNavTopListFragment } from '../MobileNavTopLists/MobileNavTopLists.generated'
import SectionSeparator from '../SectionSeparator'
import NavMenuItemLink from '../NavMenuItemLink'
import NavListContainer from '../NavListContainer/index'
import MobileNavTopLists from '../MobileNavTopLists'
import MobileNavArticleCarousel, {
  MobileNavArticleCarouselProps
} from '../MobileNavArticleCarousel'

interface NavListContentProps {
  articleCarouselProps?: MobileNavArticleCarouselProps
  topLists?: SellableEditorialTopListRevisionForMobileNavTopListFragment[]
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  testId?: string
}

const NavListContent: React.FC<NavListContentProps> = ({
  articleCarouselProps,
  topLists,
  onNavigate,
  testId
}) => (
  <SectionSeparator>
    <NavListContainer testId={testId}>
      <NavMenuItemLink
        label="Expert Journal"
        to="/journal"
        onClick={() =>
          window.tracking?.track(
            new ArticleHubEntryPointClickedEvent({
              entry_point: 'header'
            })
          )
        }
        onNavigate={onNavigate}
        testId="mobile-nav-expert-journal"
      />
      <NavMenuItemLink
        label="Curated Lists"
        to="/curated-lists"
        onClick={() =>
          window.tracking?.track(
            new ArticleHubEntryPointClickedEvent({
              entry_point: 'header'
            })
          )
        }
        onNavigate={onNavigate}
        testId="mobile-nav-curated-lists"
      />
    </NavListContainer>
    {topLists && topLists.length > 0 && <MobileNavTopLists topLists={topLists} />}
    {articleCarouselProps && articleCarouselProps.articles.length > 0 && (
      <MobileNavArticleCarousel {...articleCarouselProps} />
    )}
  </SectionSeparator>
)

export default NavListContent
