import React from 'react'
import { formatDate } from '@deal/date-utils'
import { formatPathUrl } from '#src/app/services/path'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import { useIdentityContext } from '#src/app/containers/Identity'
import ReleaseConsumerIdentityLink from '#src/app/components/ReleaseConsumerIdentityLink'
import SubSectionSeparator from '../SubSectionSeparator'
import ReferralUpsell from '../ReferralUpsell'
import NavMenuWrapper, { SharedNavMenuWrapperProps } from '../NavMenuWrapper'
import NavMenuItemLink from '../NavMenuItemLink'
import NavListHeader from '../NavListHeader'
import NavListContainer from '../NavListContainer'
import CurationsIcon from '../../icons/curations.svg'
import { useIsNavMenuAccountActiveAtom } from '../../atoms'
import RewardsIcon from './icons/rewards.svg'
import AccountIcon from './icons/account.svg'
import styles from './styles.css'

interface NavMenuAccountProps extends SharedNavMenuWrapperProps {
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

/**
 *  Account Navigation
 */
const NavMenuAccount: React.FC<NavMenuAccountProps> = ({ onNavigate, showPathCta }) => {
  const [isAccountNavActive, setIsAccountNavActive] = useIsNavMenuAccountActiveAtom()
  const referralUrl = useReferralUrl({ source: 'nav-bar' })
  const { myself } = useIdentityContext()
  const isImpersonating = myself && myself.user.id !== myself.realUser.id
  const firstName = myself?.user.firstName
  const userExpertApplications = myself?.user.expertApplicationDrafts

  return (
    <NavMenuWrapper
      containerId="account-navigation"
      containerClassName={styles.navigationContainerAccount}
      isActive={isAccountNavActive}
      isChildActive={false}
      label={firstName || 'My Account'}
      onBack={() => setIsAccountNavActive(false)}
      onNavigate={onNavigate}
      showAccountHeader={false}
      showPathCta={showPathCta}
    >
      <NavListContainer>
        <NavMenuItemLink
          label="Account"
          to="/account/personal-information"
          icon={AccountIcon}
          onNavigate={onNavigate}
        />
        <NavMenuItemLink
          label="My Curations"
          to="/curations"
          icon={CurationsIcon}
          onNavigate={onNavigate}
        />
        <NavMenuItemLink
          label="Rewards Credit"
          to="/account/rewards-credit"
          icon={RewardsIcon}
          onNavigate={onNavigate}
        />
      </NavListContainer>
      <SubSectionSeparator />
      <NavListContainer>
        <NavMenuItemLink label="My Orders" to="/account/orders" onNavigate={onNavigate} />
        <NavMenuItemLink label="My Addresses" to="/account/addresses" onNavigate={onNavigate} />
        <NavMenuItemLink label="Gift Cards" to="/account/gift-cards" onNavigate={onNavigate} />
        <NavMenuItemLink
          label="Notification Settings"
          to="/account/notification-settings"
          onNavigate={onNavigate}
        />
      </NavListContainer>
      {!!userExpertApplications?.length && (
        <>
          <SubSectionSeparator />
          <NavListHeader>{`Expert application${
            userExpertApplications?.length > 1 ? 's' : ''
          } in progress`}</NavListHeader>
          <NavListContainer>
            {userExpertApplications.map(application => (
              <NavMenuItemLink
                key={application.id}
                label={
                  application.category?.categoryAssetRevision?.expert?.singular || 'Curated Expert'
                }
                to={formatPathUrl({ pathGraphFlowId: application.pathGraphFlowId })}
                copy={formatDate(new Date(), {
                  format: 'long',
                  includeTime: true,
                  includeDayOfWeek: true
                })}
                onNavigate={onNavigate}
              />
            ))}
          </NavListContainer>
        </>
      )}
      <SubSectionSeparator />
      <NavListContainer>
        {isImpersonating && (
          <ReleaseConsumerIdentityLink className={styles.stopImpersonationLink}>
            Stop impersonating {myself?.user.firstName}
          </ReleaseConsumerIdentityLink>
        )}
        <NavMenuItemLink label="Sign Out" to="/auth/logout" noFollow onNavigate={onNavigate} />
      </NavListContainer>
      {referralUrl && <ReferralUpsell referralUrl={referralUrl} onNavigate={onNavigate} />}
    </NavMenuWrapper>
  )
}

export default NavMenuAccount
