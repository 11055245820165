import React from 'react'
import FooterNavItem, { FooterNavItemProps } from '../FooterNavItem'
import NavLink, { NavLinkProps } from '../../NavLink'

const FooterNavItemLink: React.FC<NavLinkProps & FooterNavItemProps> = ({
  to,
  noFollow,
  onClick,
  onNavigate,
  label,
  copy,
  icon
}) => (
  <li>
    <NavLink to={to} noFollow={noFollow} onClick={onClick} onNavigate={onNavigate}>
      <FooterNavItem label={label} copy={copy} icon={icon} />
    </NavLink>
  </li>
)

export default FooterNavItemLink
