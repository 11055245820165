import React from 'react'
import { Link } from '@deal/router'
import { Image } from '@deal/components'
import { ArticleHubEntryPointClickedEvent } from '#src/app/events/ArticleHubEntryPointClickedEvent'
import { ArticleEntryPointClickedEvent } from '#src/app/events/ArticleEntryPointClickedEvent'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import PlaceholderImage from '#src/app/assets/images/placeholder.png'
import { ArticleRevisionForMobileNavArticleCarouselFragment } from './MobileNavArticleCarousel.generated'
import NavMenuItemLink from '../NavMenuItemLink'
import styles from './styles.css'

export interface MobileNavArticleCarouselProps {
  navLevelId: string
  articles: ArticleRevisionForMobileNavArticleCarouselFragment[]
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const MobileNavArticleCarousel: React.FC<MobileNavArticleCarouselProps> = ({
  navLevelId,
  articles,
  onNavigate
}) => {
  const { isBot = true } = useUserAgentContext()

  if (!articles?.length) {
    return null
  }

  return (
    <>
      <div className={styles.midSectionSeparator} />
      <div className={styles.subheader}>Worth reading</div>
      <div
        className={styles.carousel}
        // prevents the flyout from closing due to swiping
        onTouchStart={e => e.stopPropagation()}
        onTouchEnd={e => e.stopPropagation()}
      >
        {articles.map(article => {
          return (
            <div key={article.id} className={styles.carouselSlide}>
              <Link
                to={`/journal/${article.friendlyId}/${article.slug}`}
                trackingRef={isBot ? undefined : 'mobile-nav-articles'}
                onClick={e => {
                  window.tracking?.track(
                    new ArticleEntryPointClickedEvent({
                      entry_point: 'header',
                      article_id: article.id
                    })
                  )
                  onNavigate(e)
                }}
              >
                <div className={styles.articleImageContainer}>
                  <Image
                    id={`article-card-image-${article.articleId}-${navLevelId}`}
                    src={article.featuredImage?.media.url || PlaceholderImage}
                    sizes="300px"
                    htmlAttributes={{
                      alt: article.featuredImage?.alternativeText || article.title
                    }}
                  />
                </div>
                <div className={styles.articleTitle}>{article.title}</div>
              </Link>
            </div>
          )
        })}
      </div>
      <div className={styles.midSectionSeparator} />
      <ul className={styles.viewAllArticlesLink}>
        <NavMenuItemLink
          label="View all Articles"
          to="/journal"
          onClick={() => {
            window.tracking?.track(
              new ArticleHubEntryPointClickedEvent({
                entry_point: 'header'
              })
            )
          }}
          onNavigate={onNavigate}
        />
      </ul>
    </>
  )
}
export default MobileNavArticleCarousel
