import { atom, useAtom } from '@deal/atomic-state'

const isNavMenuAccountActiveAtom = atom<boolean>(false)
const activeNavMenuL2Atom = atom<string | undefined>()
const activeNavMenuL3Atom = atom<string | undefined>()
const activeNavMenuL4Atom = atom<string | undefined>()

export const useIsNavMenuAccountActiveAtom = () => useAtom(isNavMenuAccountActiveAtom)
export const useActiveNavMenuL2Atom = () => useAtom(activeNavMenuL2Atom)
export const useActiveNavMenuL3Atom = () => useAtom(activeNavMenuL3Atom)
export const useActiveNavMenuL4Atom = () => useAtom(activeNavMenuL4Atom)
