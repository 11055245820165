import React from 'react'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import loggerClient from '#src/app/services/loggerClient'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import { FormattedCategoryNavigationRevisionForNavMenuL3Fragment } from './NavMenuL3.generated'
import SubSectionSeparator from '../SubSectionSeparator'
import SectionSeparator from '../SectionSeparator'
import ReferralUpsell from '../ReferralUpsell'
import NavMenuWrapper, { SharedNavMenuWrapperProps } from '../NavMenuWrapper'
import NavMenuL4 from '../NavMenuL4'
import NavMenuItemSaleLink from '../NavMenuItemSaleLink'
import NavMenuItemLink from '../NavMenuItemLink'
import NavMenuItemButton from '../NavMenuItemButton'
import NavListHeader from '../NavListHeader/index'
import NavListContent from '../NavListContent'
import NavListContainer from '../NavListContainer'
import { useActiveNavMenuL3Atom, useActiveNavMenuL4Atom } from '../../atoms'

interface NavMenuL3Props extends SharedNavMenuWrapperProps {
  navigation: FormattedCategoryNavigationRevisionForNavMenuL3Fragment
  otherNavigations: FormattedCategoryNavigationRevisionForNavMenuL3Fragment[]
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

/**
 * L3 - Sections - Categories
 */
const NavMenuL3: React.FC<NavMenuL3Props> = ({
  navigation,
  otherNavigations,
  onNavigate,
  showPathCta
}) => {
  const [activeNavMenuL3, setActiveNavMenuL3] = useActiveNavMenuL3Atom()
  const [activeNavMenuL4, setActiveNavMenuL4] = useActiveNavMenuL4Atom()
  const referralUrl = useReferralUrl({ source: 'nav-bar' })
  const productListingLocation = useProductListingLocation()
  const categorySlug = navigation.category?.slug

  return (
    <NavMenuWrapper
      containerId={navigation.id}
      navigationCategory={navigation.category || undefined}
      isActive={activeNavMenuL3 === navigation.id}
      isChildActive={
        !!activeNavMenuL4 && navigation.sections.some(section => section.id === activeNavMenuL4)
      }
      label={navigation.displayLabel}
      onBack={() => setActiveNavMenuL3(undefined)}
      onNavigate={onNavigate}
      showPathCta={showPathCta}
    >
      <NavListContainer>
        {navigation.category && (
          <NavMenuItemLink
            label="Shop All"
            to={buildCategoryOrProductListingPageUrl(navigation.category)}
            onNavigate={onNavigate}
          />
        )}
        {navigation.sections.map(section => {
          if (section.items.length > 0) {
            return (
              <NavMenuItemButton
                key={section.id}
                label={section.displayLabel}
                onClick={() => setActiveNavMenuL4(section.id)}
              >
                <NavMenuL4
                  navigation={navigation}
                  section={section}
                  onNavigate={onNavigate}
                  showPathCta={showPathCta}
                />
              </NavMenuItemButton>
            )
          }

          if (!section.displayLabelLink?.relativeConsumerWebPath) {
            loggerClient.captureError(
              new Error(
                `Attempted to render nav section link without link: ${section.displayLabel} ${categorySlug}`
              )
            )
            return null
          }

          return (
            <NavMenuItemLink
              key={section.id}
              label={section.displayLabel}
              to={section.displayLabelLink.relativeConsumerWebPath}
              noFollow={!section.displayLabelLink.seoShouldFollow}
              onNavigate={onNavigate}
            />
          )
        })}
      </NavListContainer>
      {/* Featured SRP */}
      {categorySlug && (
        <>
          <SubSectionSeparator />
          <NavListContainer>
            <NavMenuItemSaleLink categorySlug={categorySlug} onNavigate={onNavigate} />
            <NavMenuItemLink
              label="Best Sellers"
              to={productListingLocation({
                categorySlug,
                sortBy: 'BEST_SELLER',
                sortOrder: 'DESC'
              })}
              onNavigate={onNavigate}
            />
            <NavMenuItemLink
              label="Trending"
              to={productListingLocation({
                categorySlug,
                sortBy: 'POPULARITY',
                sortOrder: 'DESC'
              })}
              onNavigate={onNavigate}
            />
          </NavListContainer>
        </>
      )}
      <NavListContent
        articleCarouselProps={{
          articles: navigation.associatedArticles,
          navLevelId: navigation.id,
          onNavigate
        }}
        topLists={navigation.associatedSellableEditorialTopLists}
        onNavigate={onNavigate}
      />
      {referralUrl ? (
        <ReferralUpsell referralUrl={referralUrl} onNavigate={onNavigate} />
      ) : (
        <SectionSeparator />
      )}
      <NavListHeader>Other categories</NavListHeader>
      <NavListContainer>
        {otherNavigations.map(navigation => (
          <NavMenuItemButton
            key={navigation.id}
            label={navigation.displayLabel}
            onClick={() => setActiveNavMenuL3(navigation.id)}
          />
        ))}
      </NavListContainer>
    </NavMenuWrapper>
  )
}

export default NavMenuL3
