import React from 'react'
import { Link } from '@deal/router'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import { useDepartment } from '#src/app/context/department'
import { useIdentityContext } from '#src/app/containers/Identity'
import MylistsIcon from '../../icons/mylists.svg'
import CurationsIcon from '../../icons/curations.svg'
import { useResetMobileNavMenuScrollPositions } from '../../hooks'
import { useIsNavMenuAccountActiveAtom } from '../../atoms'
import LogoIcon from './logo.svg'
import AccountBgIcon from './account-bg.svg'
import styles from './styles.css'

interface NavMenuAccountHeaderProps {
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const NavMenuAccountHeader: React.FC<NavMenuAccountHeaderProps> = ({ onNavigate }) => {
  const [, setIsNavMenuAccountActive] = useIsNavMenuAccountActiveAtom()
  const department = useDepartment()
  const { myself, incompleteUser } = useIdentityContext()
  const resetMobileNavMenuScrollPositions = useResetMobileNavMenuScrollPositions()

  const logoUrl = department
    ? buildCategoryOrProductListingPageUrl(department.topLevelCategory)
    : '/'

  if (myself && !incompleteUser) {
    const userFirstName = myself.user.firstName
    return (
      <>
        <div
          className={styles.accountHeaderContainer}
          onClick={() => {
            resetMobileNavMenuScrollPositions()
            setIsNavMenuAccountActive(true)
          }}
          data-testid="mobile-nav-account-header"
        >
          <div className={styles.accountButtonContainer}>
            <AccountBgIcon />
            {userFirstName || <div className={styles.accountLink}>My account</div>}
          </div>
          {!!userFirstName && <div className={styles.accountLink}>My account</div>}
        </div>
        <Link to="/curations" onClick={onNavigate} className={styles.curationLink}>
          <CurationsIcon />
          My Curations
        </Link>
        {myself.influencer && (
          <Link
            className={styles.curationLink}
            to={`/mylists/${myself?.influencer.profile.vanityId || myself?.influencer.profile.id}`}
          >
            <MylistsIcon />
            My Lists
          </Link>
        )}
      </>
    )
  }

  return (
    <div className={styles.accountHeaderContainer}>
      <Link to={logoUrl} onClick={onNavigate}>
        <LogoIcon />
      </Link>
      <Link to="/auth/login" onClick={onNavigate}>
        <div className={styles.accountButtonContainer}>
          <AccountBgIcon />
          Sign in
        </div>
      </Link>
    </div>
  )
}

export default NavMenuAccountHeader
