import React, { useEffect } from 'react'
import { Flyout } from '@deal/components'
import { useIdentityContext } from '#src/app/containers/Identity'
import { CategoryNavigationDecoratedGroupForNavMenuL1Fragment } from '../NavMenuL1/NavMenuL1.generated'
import { CategoryForGetClosestNavigationForCategoryFragment } from './MobileNavFlyout.generated'
import NavMenuL1 from '../NavMenuL1'
import NavMenuAccount from '../NavMenuAccount'
import { useResetMobileNavMenuScrollPositions } from '../../hooks'
import {
  useActiveNavMenuL2Atom,
  useActiveNavMenuL3Atom,
  useActiveNavMenuL4Atom,
  useIsNavMenuAccountActiveAtom
} from '../../atoms'
import styles from './styles.css'

const getClosestNavigationForCategory = ({
  category,
  navigationGroups
}: {
  category: CategoryForGetClosestNavigationForCategoryFragment
  navigationGroups: CategoryNavigationDecoratedGroupForNavMenuL1Fragment[]
}) => {
  const department = category.department
  const topLevelCategory = category.parents.at(1)
  const secondLevelCategory = category.parents.at(2)

  for (const navigationGroup of navigationGroups) {
    // it's possible to match the department but not have a navigation item like winter-sports
    const isNavigationGroupForDepartment = navigationGroup.navigationRevisions.some(
      navigation => department && navigation.category?.department?.id == department.id
    )

    // find the closest navigation by first checking the second level category. this is for second level categories
    //   that we treat as top level like in winter-sports and fishing.
    //   if it can't find anything, check the top level category
    //   lastly, check the category itself in case it is the top level category
    const navigation =
      (secondLevelCategory &&
        navigationGroup.navigationRevisions.find(
          navigation => navigation.category?.id == secondLevelCategory.id
        )) ||
      (topLevelCategory &&
        navigationGroup.navigationRevisions.find(
          navigation => navigation.category?.id == topLevelCategory.id
        )) ||
      navigationGroup.navigationRevisions.find(navigation => navigation.category?.id == category.id)

    if (isNavigationGroupForDepartment) {
      return {
        groupSlug: navigationGroup.slug,
        navigationId: navigation?.id
      }
    }
  }

  return undefined
}

interface MobileNavFlyoutProps {
  isOpen: boolean
  onClose: () => void
  navigationGroups: CategoryNavigationDecoratedGroupForNavMenuL1Fragment[]
  category?: CategoryForGetClosestNavigationForCategoryFragment
  showPathCta: boolean
}

const MobileNavFlyout: React.FC<MobileNavFlyoutProps> = ({
  isOpen,
  onClose,
  navigationGroups,
  category,
  showPathCta
}) => {
  const { myself } = useIdentityContext()

  const closestNavigation =
    category && getClosestNavigationForCategory({ category, navigationGroups })

  const [, setIsNavMenuAccountActive] = useIsNavMenuAccountActiveAtom()
  const [, setActiveNavMenuL2] = useActiveNavMenuL2Atom()
  const [, setActiveNavMenuL3] = useActiveNavMenuL3Atom()
  const [, setActiveNavMenuL4] = useActiveNavMenuL4Atom()

  const resetMobileNavMenuleScrollPositions = useResetMobileNavMenuScrollPositions()

  useEffect(() => {
    // Reset active nav levels and scroll positions after the flyout closes or when category changes while flyout is closed
    if (!isOpen) {
      const timeout = setTimeout(() => {
        // Reset active nav levels
        setIsNavMenuAccountActive(false)
        setActiveNavMenuL2(closestNavigation?.groupSlug)
        setActiveNavMenuL3(closestNavigation?.navigationId)
        setActiveNavMenuL4(undefined)
        // The scroll position needs to be reset if the default nav level when opened is the same as when it closes
        resetMobileNavMenuleScrollPositions()
      }, 400)

      return () => clearTimeout(timeout)
    }
  }, [isOpen, closestNavigation?.groupSlug, closestNavigation?.navigationId])

  const handleClose = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e?.stopPropagation()
    onClose()
  }

  return (
    <Flyout
      isOpen={isOpen}
      onClose={handleClose}
      from="left"
      width="small"
      showCloseIcon
      alwaysRenderChildren
    >
      <nav aria-label="primary" className={styles.flyoutContent}>
        {myself && <NavMenuAccount onNavigate={handleClose} showPathCta={showPathCta} />}
        {navigationGroups.length > 0 && (
          <NavMenuL1
            navigationGroups={navigationGroups}
            onNavigate={handleClose}
            showPathCta={showPathCta}
          />
        )}
      </nav>
    </Flyout>
  )
}

export default MobileNavFlyout
