import React from 'react'
import { Location, LocationDescriptor } from 'history'
import { Link } from '@deal/router'
import styles from './styles.css'

export interface NavLinkProps {
  to: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>)
  noFollow?: boolean
  onClick?: () => void
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const NavLink: React.FC<React.PropsWithChildren<NavLinkProps>> = ({
  noFollow,
  onClick,
  onNavigate,
  ...rest
}) => (
  <Link
    rel={noFollow ? 'nofollow' : undefined}
    onClick={e => {
      onClick?.()
      onNavigate(e)
    }}
    className={styles.link}
    {...rest}
  />
)

export default NavLink
