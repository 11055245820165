import React from 'react'
import NavMenuItem from '../NavMenuItem'
import NavButton, { NavButtonProps } from '../NavButton'

interface NavMenuItemButtonProps extends NavButtonProps {
  label: string
}

const NavMenuItemButton: React.FC<React.PropsWithChildren<NavMenuItemButtonProps>> = ({
  label,
  onClick,
  children
}) => (
  <li>
    <NavButton onClick={onClick}>
      <NavMenuItem label={label} />
    </NavButton>
    {children}
  </li>
)

export default NavMenuItemButton
