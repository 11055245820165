import React from 'react'
import loggerClient from '#src/app/services/loggerClient'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import {
  FormattedCategoryNavigationRevisionForNavMenuL4Fragment,
  FormattedCategoryNavigationSectionForNavMenuL4Fragment
} from './NavMenuL4.generated'
import SectionSeparator from '../SectionSeparator'
import ReferralUpsell from '../ReferralUpsell'
import NavMenuWrapper, { SharedNavMenuWrapperProps } from '../NavMenuWrapper'
import NavMenuItemLink from '../NavMenuItemLink'
import NavMenuItemButton from '../NavMenuItemButton'
import NavListHeader from '../NavListHeader'
import NavListContent from '../NavListContent'
import NavListContainer from '../NavListContainer'
import { useActiveNavMenuL4Atom } from '../../atoms'

interface NavMenuL4Props extends SharedNavMenuWrapperProps {
  navigation: FormattedCategoryNavigationRevisionForNavMenuL4Fragment
  section: FormattedCategoryNavigationSectionForNavMenuL4Fragment
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

/**
 *  L4 - Items - Subcategories
 */
const NavMenuL4: React.FC<NavMenuL4Props> = ({ navigation, section, onNavigate, showPathCta }) => {
  const [activeNavMenuL4, setActiveNavMenuL4] = useActiveNavMenuL4Atom()
  const referralUrl = useReferralUrl({ source: 'nav-bar' })

  return (
    <NavMenuWrapper
      containerId={section.id}
      navigationCategory={navigation.category || undefined}
      isActive={activeNavMenuL4 === section.id}
      isChildActive={false}
      label={section.displayLabel}
      onBack={() => setActiveNavMenuL4(undefined)}
      onNavigate={onNavigate}
      showPathCta={showPathCta}
    >
      <NavListContainer>
        {section.displayLabelLink?.relativeConsumerWebPath && (
          <NavMenuItemLink
            label="Shop All"
            to={section.displayLabelLink.relativeConsumerWebPath}
            noFollow={!section.displayLabelLink.seoShouldFollow}
            onNavigate={onNavigate}
          />
        )}
        {section.items.map(item => {
          if (!item.displayLabelLink?.relativeConsumerWebPath) {
            loggerClient.captureError(
              new Error(`Attempted to render nav item link without link: ${item.displayLabel}`)
            )
            return null
          }

          return (
            <NavMenuItemLink
              key={item.id}
              label={item.displayLabel}
              to={item.displayLabelLink.relativeConsumerWebPath}
              noFollow={!item.displayLabelLink.seoShouldFollow}
              onNavigate={onNavigate}
            />
          )
        })}
      </NavListContainer>
      <NavListContent
        articleCarouselProps={{
          articles: navigation.associatedArticles,
          navLevelId: section.id,
          onNavigate
        }}
        onNavigate={onNavigate}
      />
      {referralUrl ? (
        <ReferralUpsell referralUrl={referralUrl} onNavigate={onNavigate} />
      ) : (
        <SectionSeparator />
      )}
      <NavListHeader>More in {navigation.displayLabel}</NavListHeader>
      <NavListContainer>
        {navigation.sections
          .filter(otherSection => otherSection.id !== section.id)
          .map(section => (
            <NavMenuItemButton
              key={section.id}
              label={section.displayLabel}
              onClick={() => setActiveNavMenuL4(section.id)}
            />
          ))}
      </NavListContainer>
    </NavMenuWrapper>
  )
}

export default NavMenuL4
