import React from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { PathSchemaSelectorInput } from '#src/generated/types'
import { formatPathUrl, getOnboardingPathSlug , useCreateRequestPath } from '#src/app/services/path'
import { CategoryForFooterNavItemPathButtonFragment } from './FooterNavItemPathButton.generated'
import FooterNavItem from '../FooterNavItem'
import NavButton from '../../NavButton'
import ChatIcon from './chat.svg'

interface FooterNavItemPathButtonProps {
  navigationCategory?: CategoryForFooterNavItemPathButtonFragment | null
}

const FooterNavItemPathButton: React.FC<FooterNavItemPathButtonProps> = ({
  navigationCategory
}) => {
  const history = useHistory()

  const [createPath, { loading: pathLoading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `Description` component!')
      }
    },
    onError: ({ errorMessage }) => toast.error(errorMessage)
  })

  const pathSelector: PathSchemaSelectorInput = navigationCategory
    ? { categorySlugHierarchyLookup: navigationCategory.slug }
    : { pathSchemaSlug: getOnboardingPathSlug() }

  return (
    <li>
      <NavButton
        onClick={() => {
          createPath({
            pathSelector,
            trackingCodes: {
              ctaName: 'mobile-nav-flyout-footer-cta',
              sourceKey: 'mobile-nav-flyout-footer-cta'
            }
          })
        }}
        disabled={pathLoading}
      >
        <FooterNavItem
          label="Chat with an Expert"
          copy="Get expert advice anytime"
          loading={pathLoading}
          icon={ChatIcon}
        />
      </NavButton>
    </li>
  )
}

export default FooterNavItemPathButton
