import React from 'react'
import { useExperiment } from '@deal/experiment-js'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import NavMenuItemLink from '../NavMenuItemLink'
import styles from './styles.css'

interface NavMenuSaleLinkProps {
  categorySlug?: string
  onNavigate: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const NavMenuSaleLink: React.FC<NavMenuSaleLinkProps> = ({ categorySlug, onNavigate }) => {
  const productListingLocation = useProductListingLocation()
  const isClearanceShown =
    useExperiment({ experiment: 'navigation-final-sale', defaultTreatment: 'control' }).result ===
    'on'

  return (
    <>
      <NavMenuItemLink
        label="Sale"
        to={
          categorySlug
            ? productListingLocation({
                categorySlug,
                filters: ['DISCOUNTED']
              })
            : '/sale'
        }
        className={styles.navigationListSaleLink}
        onNavigate={onNavigate}
      />
      {isClearanceShown && (
        <NavMenuItemLink
          label="Clearance sale"
          to={
            categorySlug
              ? productListingLocation({
                  categorySlug,
                  filters: ['CLEARANCE']
                })
              : '/clearance'
          }
          className={styles.navigationListClearanceLink}
          onNavigate={onNavigate}
        />
      )}
    </>
  )
}

export default NavMenuSaleLink
