import React from 'react'
import classnames from 'classnames'
import { BubbleLoader } from '@deal/components'
import styles from './styles.css'

export interface FooterNavItemProps {
  label: string
  copy?: string
  loading?: boolean
  icon?: React.FC<React.PropsWithChildren<React.SVGAttributes<SVGElement>>>
}

const FooterNavItem: React.FC<FooterNavItemProps> = ({ label, copy, loading, icon: Icon }) => (
  <div className={styles.navItem}>
    {Icon && <Icon className={styles.icon} />}
    <div className={styles.labelContainer}>
      <div className={classnames({ [styles.loading]: loading })}>
        <div>{label}</div>
        <div className={styles.navItemCopy}>{copy}</div>
      </div>
      {loading && <BubbleLoader className={styles.loader} color="var(--c-black-50)" />}
    </div>
  </div>
)

export default FooterNavItem
